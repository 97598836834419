/* maven-pro-400normal - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Maven Pro Regular '),
    local('Maven Pro-Regular'),
    url('./files/maven-pro-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/maven-pro-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* maven-pro-500normal - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Maven Pro Medium '),
    local('Maven Pro-Medium'),
    url('./files/maven-pro-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/maven-pro-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* maven-pro-700normal - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Maven Pro Bold '),
    local('Maven Pro-Bold'),
    url('./files/maven-pro-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/maven-pro-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* maven-pro-900normal - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Maven Pro Black '),
    local('Maven Pro-Black'),
    url('./files/maven-pro-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/maven-pro-latin-900.woff') format('woff'); /* Modern Browsers */
}

